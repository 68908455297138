import styles from "./style.module.css";

import React, { useState, useCallback } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import LazyLoad from "react-lazy-load";
import PreviewImage from "../previewImage/PreviewImage";

const ImageGallery = ({ enabled = true, images = [], galleryStyle = "" }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  switch (galleryStyle) {
    case "Mosaic":
      if (!enabled) return null;
      if (images.length <= 3) {
        return (
          <>
            <div className={styles.facebookV1}>
              {images[0] ? (
                <LazyLoad height={400}>
                  <img
                    alt="gallery item"
                    src={images[0]}
                    className={styles.mosaicImageItem}
                    onClick={() => openImageViewer(0)}
                  />
                </LazyLoad>
              ) : (
                <div className={styles.mosaicImageItem} />
              )}
              <div className={styles.rightContainerV1}>
                {images[1] ? (
                  <LazyLoad height={197.5}>
                    <img
                      alt="gallery item"
                      src={images[1]}
                      className={styles.mosaicImageItem}
                      onClick={() => openImageViewer(1)}
                    />
                  </LazyLoad>
                ) : (
                  <div />
                )}
                {images[2] ? (
                  <LazyLoad height={197.5}>
                    <img
                      alt="gallery item"
                      src={images[2]}
                      className={styles.mosaicImageItem}
                      onClick={() => openImageViewer(2)}
                    />
                  </LazyLoad>
                ) : null}
              </div>
            </div>
            {isViewerOpen && (
              <PreviewImage
                images={images}
                currentImage={currentImage}
                closeImageViewer={closeImageViewer}
              />
            )}
          </>
        );
      }
      if (images.length === 4) {
        return (
          <>
            <div className={styles.facebookV2}>
              {images[0] ? (
                <LazyLoad height={400}>
                  <img
                    alt="gallery item"
                    src={images[0]}
                    className={styles.mosaicImageItem}
                    onClick={() => openImageViewer(0)}
                  />
                </LazyLoad>
              ) : (
                <div className={styles.mosaicImageItem} />
              )}
              <div className={styles.rightContainerV2}>
                {images[1] ? (
                  <LazyLoad height={130}>
                    <img
                      alt="gallery item"
                      src={images[1]}
                      className={styles.mosaicImageItem}
                      onClick={() => openImageViewer(1)}
                    />
                  </LazyLoad>
                ) : (
                  <div />
                )}
                {images[2] ? (
                  <LazyLoad height={130}>
                    <img
                      alt="gallery item"
                      src={images[2]}
                      className={styles.mosaicImageItem}
                      onClick={() => openImageViewer(2)}
                    />
                  </LazyLoad>
                ) : (
                  <div />
                )}
                {images[3] ? (
                  <LazyLoad height={130}>
                    <img
                      alt="gallery item"
                      src={images[3]}
                      className={styles.mosaicImageItem}
                      onClick={() => openImageViewer(3)}
                    />
                  </LazyLoad>
                ) : (
                  <div />
                )}
              </div>
            </div>
            {isViewerOpen && (
              <PreviewImage
                images={images}
                currentImage={currentImage}
                closeImageViewer={closeImageViewer}
              />
            )}
          </>
        );
      }
      if (images.length >= 5) {
        return (
          <>
            <div className={styles.facebookV3}>
              <div className={styles.topContainerV3}>
                {images[0] ? (
                  <LazyLoad height={245}>
                    <img
                      alt="gallery item"
                      src={images[0]}
                      className={styles.mosaicImageItem}
                      onClick={() => openImageViewer(0)}
                    />
                  </LazyLoad>
                ) : (
                  <div />
                )}
                {images[1] ? (
                  <LazyLoad height={245}>
                    <img
                      alt="gallery item"
                      src={images[1]}
                      className={styles.mosaicImageItem}
                      onClick={() => openImageViewer(1)}
                    />
                  </LazyLoad>
                ) : (
                  <div />
                )}
              </div>
              <div className={styles.bottomContainerV3}>
                {images[2] ? (
                  <LazyLoad height={150}>
                    <img
                      alt="gallery item"
                      src={images[2]}
                      className={styles.mosaicImageItem}
                      onClick={() => openImageViewer(2)}
                    />
                  </LazyLoad>
                ) : (
                  <div />
                )}
                {images[3] ? (
                  <LazyLoad height={150}>
                    <img
                      alt="gallery item"
                      src={images[3]}
                      className={styles.mosaicImageItem}
                      onClick={() => openImageViewer(3)}
                    />
                  </LazyLoad>
                ) : (
                  <div />
                )}
                {images[4] ? (
                  <LazyLoad height={150}>
                    <div className={styles.imageCounterWrapper}>
                      <img
                        alt="gallery item"
                        src={images[4]}
                        className={styles.mosaicImageItem}
                        onClick={() => openImageViewer(4)}
                      />
                      {images.length > 5 && (
                        <span
                          className={styles.imageCounter}
                          style={{ lineHeight: "150px" }}
                          onClick={() => openImageViewer(4)}
                        >
                          +{images.length - 5}
                        </span>
                      )}
                    </div>
                  </LazyLoad>
                ) : (
                  <div />
                )}
              </div>
            </div>
            {isViewerOpen && (
              <PreviewImage
                images={images}
                currentImage={currentImage}
                closeImageViewer={closeImageViewer}
              />
            )}
          </>
        );
      }
      break;
    case "Grid":
      if (!enabled) return null;
      return (
        <>
          <div className={styles.gridGallery}>
            <div className={styles.gridGalleryRow}>
              {images[0] ? (
                <LazyLoad height={133}>
                  <img
                    alt="gallery item"
                    src={images[0]}
                    className={styles.gridGalleryItem}
                    onClick={() => openImageViewer(0)}
                  />
                </LazyLoad>
              ) : (
                <div className={styles.gridGalleryItem} />
              )}
              {images[1] ? (
                <LazyLoad height={133}>
                  <img
                    alt="gallery item"
                    src={images[1]}
                    className={styles.gridGalleryItem}
                    onClick={() => openImageViewer(1)}
                  />
                </LazyLoad>
              ) : (
                <div className={styles.gridGalleryItem} />
              )}
              {images[2] ? (
                <LazyLoad height={133}>
                  <img
                    alt="gallery item"
                    src={images[2]}
                    className={styles.gridGalleryItem}
                    onClick={() => openImageViewer(2)}
                  />
                </LazyLoad>
              ) : (
                <div className={styles.gridGalleryItem} />
              )}
            </div>
            <div className={styles.gridGalleryRow}>
              {images[3] ? (
                <LazyLoad height={133}>
                  <img
                    alt="gallery item"
                    src={images[3]}
                    className={styles.gridGalleryItem}
                    onClick={() => openImageViewer(3)}
                  />
                </LazyLoad>
              ) : (
                <div className={styles.gridGalleryItem} />
              )}
              {images[4] ? (
                <LazyLoad height={133}>
                  <img
                    alt="gallery item"
                    src={images[4]}
                    className={styles.gridGalleryItem}
                    onClick={() => openImageViewer(4)}
                  />
                </LazyLoad>
              ) : (
                <div className={styles.gridGalleryItem} />
              )}
              {images[5] ? (
                <LazyLoad height={133}>
                  <img
                    alt="gallery item"
                    src={images[5]}
                    className={styles.gridGalleryItem}
                    onClick={() => openImageViewer(5)}
                  />
                </LazyLoad>
              ) : (
                <div className={styles.gridGalleryItem} />
              )}
            </div>
            <div className={styles.gridGalleryRow}>
              {images[6] ? (
                <LazyLoad height={133}>
                  <img
                    alt="gallery item"
                    src={images[6]}
                    className={styles.gridGalleryItem}
                    onClick={() => openImageViewer(6)}
                  />
                </LazyLoad>
              ) : (
                <div className={styles.gridGalleryItem} />
              )}
              {images[7] ? (
                <LazyLoad height={133}>
                  <img
                    alt="gallery item"
                    src={images[7]}
                    className={styles.gridGalleryItem}
                    onClick={() => openImageViewer(7)}
                  />
                </LazyLoad>
              ) : (
                <div className={styles.gridGalleryItem} />
              )}
              {images[8] ? (
                <LazyLoad height={133}>
                  <div className={styles.imageCounterWrapper}>
                    <img
                      alt="gallery item"
                      src={images[8]}
                      className={styles.gridGalleryItem}
                      onClick={() => openImageViewer(8)}
                    />
                    {images.length > 9 && (
                      <span
                        className={styles.imageCounter}
                        style={{ lineHeight: "133px" }}
                        onClick={() => openImageViewer(8)}
                      >
                        +{images.length - 9}
                      </span>
                    )}
                  </div>
                </LazyLoad>
              ) : (
                <div className={styles.gridGalleryItem} />
              )}
            </div>
          </div>
          {isViewerOpen && (
            <PreviewImage
              images={images}
              currentImage={currentImage}
              closeImageViewer={closeImageViewer}
            />
          )}
        </>
      );
    case "Pager":
      if (!enabled) return null;
      return (
        <Splide
          aria-label="Splide Image Gallery"
          options={{
            fixedWidth: 368,
            fixedHeight: 400,
            width: 368,
            height: 400,
            pagination: false,
            lazyLoad: true,
            preloadPages: 1,
            breakpoints: {
              400: {
                fixedWidth: 0,
              },
            },
          }}
        >
          {images.map((link, index) => (
            <SplideSlide key={index}>
              <img
                src={link}
                alt={`slide ${index + 1}`}
                data-splide-lazy={link}
                className={styles.splideImg}
              />
            </SplideSlide>
          ))}
        </Splide>
      );
    default:
      return null;
  }
};

export default ImageGallery;
