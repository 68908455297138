import axios from "axios";
import { badgesEndpoints } from "./endpoints";

export const getBadgesByUrl = async (path, uidQuery) => {
  try {
    const response = await axios.get(
      `${badgesEndpoints.getBadgeByUrl + path + uidQuery}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const postUserStatistics = async (data) => {
  try {
    await axios.post(badgesEndpoints.sendUserStatistics, data);
  } catch (error) {
    console.log(error);
  }
};

export const countPageClicks = async (data) => {
  try {
    await axios.post(badgesEndpoints.countPageClicks, data);
  } catch (error) {
    console.log(error);
  }
};
