import React, { useEffect, useState, useRef } from "react";
import "./assets/styles/fonts.css";
import "./assets/carousel/splide.min.css";

import { getBadgesByUrl, postUserStatistics, countPageClicks } from "./api";
import { themeStyleBg } from "./utils";
import RenderedTemplate from "./components/renderedTemplate/RenderedTemplate";
import Loader from "./components/loader/Loader";

function App() {
  const dateRef = useRef(new Date().getTime());
  const [badge, setBadge] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(true);
  const [isBadgeAssigned, setIsBadgeAssigned] = useState(false);
  const [contentHeight, setContentHeight] = useState("");
  const [isReviewOpened, setIsReviewOpened] = useState(false);
  const [reviewFunnel, setReviewFunnel] = useState({
    redirect: false,
    url: "",
  });

  const themeStyle =
    !badge?.theme?.bg_color && !badge?.theme?.bg_image_url
      ? {
          backgroundImage: `url(${themeStyleBg(badge?.theme?.theme_style)})`,
        }
      : {
          backgroundImage: `url(${badge?.theme?.bg_image_url})`,
          backgroundColor: badge?.theme?.bg_color,
        };

  const retrieveBadgeDoc = async () => {
    setIsLoadingTemplate(true);
    const data = await getBadgesByUrl(
      window.location.pathname,
      window.location.search
    );
    setBadge(data);
    setIsBadgeAssigned(data?.user?.uid ? true : false);
    setIsLoadingTemplate(false);

    setUserData({
      ip_address: data.ip,
      doc_id: data.id,
    });

    if (data?.settings?.ask_gps_location && !window.location.search) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          postUserStatistics({
            gps_location: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            date: dateRef.current,
            badge_link: data.link,
            ip_address: data.ip,
            doc_id: data.id,
          });
        },
        () => {
          postUserStatistics({
            badge_link: data.link,
            ip_address: data.ip,
            doc_id: data.id,
            date: dateRef.current,
          });
        }
      );
    }

    const businessReviewSection = data.template.find(
      (section) => section.section_type === "business_review_card"
    );

    if (
      businessReviewSection &&
      !businessReviewSection?.show_review_funnel &&
      businessReviewSection?.review_url &&
      !window.location.search
    ) {
      setReviewFunnel({
        redirect: true,
        url: businessReviewSection?.review_url,
      });
    }

    setBadge(data);
    setIsLoadingTemplate(false);
  };

  const countClick = () => {
    countPageClicks({
      ...userData,
      date: dateRef.current,
    });
  };

  useEffect(() => {
    if (window.location.pathname === "/") return;

    retrieveBadgeDoc();
  }, []);

  useEffect(() => {
    if (!badge) return;

    if (reviewFunnel.redirect) {
      window.location.href = reviewFunnel.url;

      return;
    }

    setTimeout(
      () => {
        if (window.innerHeight < document.getElementById("footer").offsetTop) {
          return setContentHeight("fit-content");
        }

        return setContentHeight("100vh");
      },
      !isReviewOpened ? 1000 : 0
    );
  }, [badge, isReviewOpened]);

  return (
    <div className="App" style={{ ...themeStyle, height: contentHeight }}>
      {!isLoadingTemplate ? (
        !reviewFunnel.redirect ? (
          <RenderedTemplate
            isPrivate={badge?.private}
            template={badge?.template}
            badgeName={badge?.badge_name}
            theme={badge?.theme}
            countClick={countClick}
            isBadgeAssigned={isBadgeAssigned}
            isReviewOpened={isReviewOpened}
            setIsReviewOpened={setIsReviewOpened}
          />
        ) : null
      ) : (
        <Loader />
      )}
      <div id="footer"></div>
    </div>
  );
}

export default App;
