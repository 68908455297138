import React, { useEffect, useState } from "react";
import cn from "classnames";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMessage,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./style.module.css";
import themeStyles from "./theme.module.css";
import ImageGallery from "../imageGallery";

import {
  getVideoHeight,
  createAddressString,
  dividerHeight,
} from "../../utils";
import LocationIcon from "../../assets/images/location-icon.svg";
import Review from "../review";
import SocialIcon from "../socialIcon";

const RenderedTemplate = ({
  template = [],
  theme = {},
  badgeName = "",
  isPrivate = false,
  countClick = () => {},
  isBadgeAssigned = false,
  isReviewOpened,
  setIsReviewOpened,
}) => {
  const [errors, setErrors] = useState({
    reviewUrl: false,
  });

  const { button_color, button_style, social_icon_size, theme_style } = theme;
  const profile = template.find((el) => el.section_type === "profile");
  const addressForVCard =
    `${profile?.address?.city}, ` +
    `${profile?.address?.country}, ` +
    `${profile?.address?.state}, ` +
    `${profile?.address?.street}, ` +
    `${profile?.address?.zip_code}`;

  const vCard = `https://us-central1-celestial-sonar-363107.cloudfunctions.net/getVCard?phone=${profile?.phone}&firstName=${profile?.title}&email=${profile?.email}&address=${addressForVCard}`;
  const isAddressValid = profile?.address
    ? Object.values(profile.address).some((el) => el !== "")
    : false;

  useEffect(() => {
    if (isBadgeAssigned || isPrivate) return;
    window.location.href = "https://shop.qrs.life/pages/qrs-life-app";
  }, []);

  const convertButtonLink = (link = "") => {
    if (new URL(link).hostname === "youtu.be") {
      return `https://www.youtube.com/${new URL(link).pathname}`;
    }

    const lowerCaseLink =
      new URL(link).origin.toLowerCase() + new URL(link).pathname;

    if (lowerCaseLink.slice(0, 3) === "www") {
      return "//" + lowerCaseLink;
    }
    return lowerCaseLink;
  };

  const getTextColorTheme = () => {
    switch (theme_style) {
      case 1 || 2 || 3:
        return themeStyles.theme1_text;
      case 4:
        return themeStyles.theme4_text;
      default:
        break;
    }
  };

  const getButtonTheme = () => {
    switch (theme_style) {
      case 1:
        return themeStyles.theme1_btn;
      case 2:
        return themeStyles.theme2_btn;
      case 3:
        return themeStyles.theme3_btn;
      case 4:
        return themeStyles.theme4_btn;
      default:
        return themeStyles.theme1_btn;
    }
  };

  const getButtonStyle = () => {
    switch (button_style) {
      case "Border Radius":
        return themeStyles.button_radius;
      case "Rounded":
        return themeStyles.button_rounded;
      case "Square":
        return themeStyles.button_square;
      default:
        return themeStyles.button_radius;
    }
  };

  const getIconTheme = () => {
    switch (theme_style) {
      case 1:
        return ["#1F2124", "#FBFBFB"];
      case 2:
        return ["#FFFFFF", "#194355"];
      case 3:
        return ["#FFFFFF", "#2A1C2F"];
      case 4:
        return ["none", "#FBFBFB", "1.2px solid rgb(255, 255, 255, 0.5)"];
      default:
        return ["#1F2124", "#FBFBFB"];
    }
  };

  const getIconSize = () => {
    switch (social_icon_size) {
      case "Small":
        return {
          width: "34px",
          height: "34px",
        };
      case "Medium":
        return {
          width: "38px",
          height: "38px",
        };
      case "Large":
        return {
          width: "48px",
          height: "48px",
        };
      default:
        return {
          width: "48px",
          height: "48px",
        };
    }
  };

  const profileIconsStyleProps = {
    ...getIconSize(),
    backgroundColor: getIconTheme()[0],
    color: getIconTheme()[1],
    border: getIconTheme()[2],
  };

  const renderTemplate = template.map((section, index) => {
    switch (section.section_type) {
      case "profile":
        if (isReviewOpened) return null;

        const checkLocationOnly = (obj) => {
          const values = Object.values(obj);
          const isGoogleLocationPresent = !!obj.google_location;

          if (isGoogleLocationPresent) {
            return values.every(
              (value) => value === "" || value === obj.google_location
            );
          } else {
            return false;
          }
        };

        const isLocationOnly = checkLocationOnly(section.address);

        return (
          <section key={index} className={styles.profile}>
            <img
              src={section?.image_url}
              alt="profile"
              className={styles.profilePicture}
            />
            <h2 className={cn(styles.title, getTextColorTheme())}>
              {section?.title}
            </h2>

            <p className={cn(styles.subTitleProfile, getTextColorTheme())}>
              {section?.description}
            </p>

            <div className={styles.contactWrapper}>
              {section?.phone ? (
                <a
                  onClick={() => countClick()}
                  href={`tel: ${section?.phone}`}
                  className={styles.profileIcon}
                  style={profileIconsStyleProps}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </a>
              ) : null}

              {section?.email ? (
                <a
                  onClick={() => countClick()}
                  href={`mailto: ${section?.email}`}
                  className={styles.profileIcon}
                  style={profileIconsStyleProps}
                >
                  <FontAwesomeIcon icon={faMessage} />
                </a>
              ) : null}

              {isLocationOnly ? (
                <a
                  onClick={() => countClick()}
                  href={section?.address?.google_location}
                  className={styles.profileIcon}
                  style={profileIconsStyleProps}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faLocationDot} />
                </a>
              ) : null}
            </div>

            {isAddressValid && !isLocationOnly ? (
              section?.address?.google_location ? (
                <a
                  onClick={() => countClick()}
                  href={section?.address?.google_location}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={styles.addressContainer}>
                    <img src={LocationIcon} alt="Location icon" />
                    <p style={{ color: "#1f2124" }}>
                      {createAddressString(section?.address)}
                    </p>
                  </div>
                </a>
              ) : (
                <div className={styles.addressContainer}>
                  <img src={LocationIcon} alt="Location icon" />
                  <p style={{ color: "#1f2124" }}>
                    {createAddressString(section?.address)}
                  </p>
                </div>
              )
            ) : null}
          </section>
        );

      case "save_contact_button":
        return (
          <div key={index}>
            <button
              onClick={() => (window.location.href = vCard)}
              className={cn(
                styles.primaryBtn,
                { [getButtonStyle()]: button_style },
                {
                  [getButtonTheme()]: !button_color,
                }
              )}
              style={{
                backgroundColor: button_color,
              }}
            >
              Save Contact
            </button>
          </div>
        );

      case "header":
        return (
          <section key={index} className={styles.header}>
            <h2 className={cn(styles.title, getTextColorTheme())}>
              {section?.title}
            </h2>

            <p className={cn(styles.subTitle, getTextColorTheme())}>
              {section?.description}
            </p>
          </section>
        );

      case "social_icons":
        return (
          <section key={index} className={styles.socialWrapper}>
            {section?.social_links.map((icon, index) => {
              return (
                <SocialIcon
                  key={index}
                  title={icon.title}
                  onClick={() => countClick()}
                  url={convertButtonLink(icon?.link)}
                  backgroundColor={getIconTheme()[0]}
                  iconColor={getIconTheme()[1]}
                  border={getIconTheme()[2]}
                  containerStyles={getIconSize()}
                />
              );
            })}
          </section>
        );

      case "divider":
        if (isReviewOpened) return null;

        return (
          <div
            key={index}
            className={styles.divider}
            style={{
              backgroundColor: section.color,
              height: dividerHeight(section?.style),
            }}
          />
        );

      case "buttons":
        return (
          <div key={index}>
            {section?.buttons.map((button, index) =>
              button?.enabled ? (
                <a
                  key={index}
                  href={button?.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    onClick={() => countClick()}
                    className={cn(
                      styles.primaryBtn,
                      { [getButtonStyle()]: button_style },
                      {
                        [getButtonTheme()]: !button_color,
                      }
                    )}
                    style={{
                      backgroundColor: button_color,
                    }}
                  >
                    {button?.title}
                  </button>
                </a>
              ) : null
            )}
          </div>
        );

      case "image_gallery":
        return (
          <section key={index} className={styles.carousel}>
            <ImageGallery
              enabled={section?.enabled}
              images={section?.images}
              galleryStyle={section?.style}
            />
          </section>
        );

      case "contact_form":
        return (
          <section key={index} className={styles.getInTouch}>
            <form action="#">
              <h3 className={styles.formTitle}>{section?.title}</h3>
              <input type="text" placeholder={section?.phone} />
              <input type="email" placeholder={section?.email} />
              <input
                type="submit"
                value={section?.button_title}
                className={styles.primaryBtn}
              />
            </form>
          </section>
        );

      case "video":
        return (
          <section key={index} className={styles.stayTuned}>
            <ReactPlayer
              url={section?.link}
              width="100%"
              height={getVideoHeight()}
              className={styles.videoWrapper}
            />
          </section>
        );

      case "business_review_card":
        return (
          <section key={index}>
            <Review
              buttonClass={cn(
                styles.primaryBtn,
                { [getButtonStyle()]: button_style },
                {
                  [getButtonTheme()]: !button_color,
                }
              )}
              isError={errors.reviewUrl}
              setErrors={setErrors}
              setIsReviewOpened={setIsReviewOpened}
              sectionData={section}
              reviewUrl={section.review_url}
              showReviewFunnel={section.show_review_funnel}
              badgeName={badgeName}
              badgeProfileEmail={profile?.email}
            />
          </section>
        );
      default:
        return null;
    }
  });

  return (
    <div className={styles.container}>
      {isPrivate ? (
        <div style={{ textAlign: "center" }}>
          This page was disabled by the owner
        </div>
      ) : (
        renderTemplate
      )}
    </div>
  );
};

export default RenderedTemplate;
