import { API_URL, API_URL_DEV } from "../constants";

export const badgesEndpoints = {
  getBadgeByUrl: API_URL + "getBadgeByUrl",
  sendUserStatistics: API_URL + "sendUserStatistics",
  countPageClicks: API_URL + "countPageClicks",
};

// Dev environment
// Comment when deploy to `prod` environment, comment the object above
// export const badgesEndpoints = {
//   getBadgeByUrl: API_URL + "getBadgeByUrlDev",
//   sendUserStatistics: API_URL + "sendUserStatisticsDev",
//   countPageClicks: API_URL + "countPageClicksDev",
// };
