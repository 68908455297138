import styles from "./style.module.css";
import stylesTemplate from "../renderedTemplate/style.module.css";

import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import cn from "classnames";

import ThankYouImage from "../../assets/images/thank-you.png";
import { db } from "../../firebase";
import { useEffect } from "react";

const RATINGS = {
  GREAT: 0,
  OK: 1,
  POOR: 2,
};

const REVIEW_STEPS = {
  RATING: 0,
  REVIEW_TEXT: 1,
  EMAIL: 2,
  THANK_YOU: 3,
};

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Review = ({
  buttonClass,
  setErrors,
  isError,
  setIsReviewOpened,
  sectionData,
  badgeName,
}) => {
  const {
    review_email,
    review_url,
    show_review_funnel,
    step_1_question,
    step_1_emoji_1,
    step_1_emoji_2,
    step_1_emoji_3,
    step_2_title,
    step_2_subtitle,
    step_3_title,
    step_3_subtitle,
    step_4_title,
    step_4_subtitle,
  } = sectionData;

  const [currentStep, setCurrentStep] = useState(REVIEW_STEPS.RATING);
  const [isValidEmail, setIsValidEmail] = useState(null);
  const [form, setForm] = useState({
    rating: null,
    review: "",
    email: "",
  });

  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleSelectRating = (rating) => {
    if (!review_url) {
      setErrors((prev) => ({ ...prev, reviewUrl: true }));

      return;
    }

    setIsReviewOpened(true);
    setForm((prev) => ({ ...prev, rating }));

    handleNextStep();
  };

  const handleSendReviewMail = async (e) => {
    e.preventDefault();

    if (!emailRegex.test(form.email)) {
      setIsValidEmail(emailRegex.test(form.email));

      return;
    }

    const currentDate = new Date().toLocaleString();

    try {
      await addDoc(collection(db, "mail"), {
        to: review_email,
        message: {
          subject: "New Customer Review",
          html: `<b>Reviewer email:</b> ${form.email} <br />
          <b>Review rating:</b> ${
            form.rating === RATINGS.OK ? "Neutral" : "Negative"
          } 
          <br />
          <br />


          <b>Review text:</b> ${form.review}

          <br />
          <br />
          <b>Review date:</b> ${currentDate} <br />
          <b>Badge name:</b> ${badgeName}`,
        },
      });

      handleNextStep();
    } catch (err) {}
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsValidEmail(null);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [form.email]);

  if (isError)
    return (
      <h2 className={stylesTemplate.title}>
        Review URL wasn’t configured, please, go to the app and configure your
        Google Business review URL
      </h2>
    );

  return (
    <div className={styles.container}>
      {currentStep === REVIEW_STEPS.RATING && (
        <>
          <h2
            className={stylesTemplate.title}
            style={{
              marginTop: "13px",
            }}
          >
            {step_1_question}
          </h2>

          <div className={styles.ratingContainer}>
            <div className={styles.iconContainer}>
              {review_url ? (
                <a href={review_url} className={styles.iconBtn}>
                  😃
                </a>
              ) : (
                <div
                  className={styles.iconBtn}
                  onClick={() => handleSelectRating(RATINGS.GREAT)}
                >
                  😃
                </div>
              )}
              <span className={styles.iconText}>{step_1_emoji_1}</span>
            </div>
            <div className={styles.iconContainer}>
              {!show_review_funnel && review_url ? (
                <a href={review_url} className={styles.iconBtn}>
                  😐
                </a>
              ) : (
                <div
                  className={styles.iconBtn}
                  onClick={() => handleSelectRating(RATINGS.OK)}
                >
                  😐
                </div>
              )}

              <span className={styles.iconText}>{step_1_emoji_2}</span>
            </div>
            <div className={styles.iconContainer}>
              {!show_review_funnel && review_url ? (
                <a href={review_url} className={styles.iconBtn}>
                  ☹️
                </a>
              ) : (
                <div
                  className={styles.iconBtn}
                  onClick={() => handleSelectRating(RATINGS.POOR)}
                >
                  ☹️
                </div>
              )}

              <span className={styles.iconText}>{step_1_emoji_3}</span>
            </div>
          </div>
        </>
      )}

      {currentStep === REVIEW_STEPS.REVIEW_TEXT && (
        <>
          <h2
            className={stylesTemplate.title}
            style={{
              marginBottom: "15px",
            }}
          >
            {step_2_title}
          </h2>
          <h3
            style={{
              marginBottom: "15px",
            }}
          >
            {step_2_subtitle}
          </h3>

          <form
            className={styles.reviewContainer}
            onSubmit={(e) => {
              e.preventDefault();

              handleNextStep();
            }}
          >
            <textarea
              name="review"
              value={form.review}
              onChange={handleChange}
              required={true}
              className={styles.reviewTextArea}
              placeholder="Share details of your own experience at this place."
              maxLength={200}
            />
            <button type="submit" className={buttonClass}>
              Post review
            </button>
          </form>
        </>
      )}

      {currentStep === REVIEW_STEPS.EMAIL && (
        <>
          <h2
            className={stylesTemplate.title}
            style={{
              marginBottom: "15px",
            }}
          >
            {step_3_title}
          </h2>
          <h3 style={{ marginBottom: "15px" }}>{step_3_subtitle}</h3>

          <form
            className={styles.reviewContainer}
            onSubmit={handleSendReviewMail}
          >
            <div className={styles.emailContainer}>
              <input
                name="email"
                value={form.email}
                onChange={handleChange}
                type="text"
                autoCapitalize="off"
                required={true}
                placeholder="Your email..."
                className={cn(styles.emailInput, {
                  [styles.emailInputError]: isValidEmail === false,
                })}
              />

              <span
                className={cn(styles.errorText, {
                  [styles.showError]: isValidEmail === false,
                })}
              >
                Invalid Email
              </span>
            </div>

            <button type="submit" className={buttonClass}>
              Submit
            </button>
          </form>
        </>
      )}

      {currentStep === REVIEW_STEPS.THANK_YOU && (
        <>
          <img
            src={ThankYouImage}
            alt="Thank you"
            className={styles.thankYouImg}
          />

          <h2
            className={stylesTemplate.title}
            style={{
              marginTop: "-100px",
              marginBottom: "15px",
            }}
          >
            {step_4_title}
          </h2>
          <h3>{step_4_subtitle}</h3>
        </>
      )}
    </div>
  );
};

export default Review;
