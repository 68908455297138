export const dividerHeight = (style) => {
  switch (style) {
    case "Thick":
      return "4px";
    case "Medium":
      return "2px";
    default:
      return "1px";
  }
};
