import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB0heaOjb8iHUZnDqcG1MANzoCevM0wFnI",
  authDomain: "celestial-sonar-363107.firebaseapp.com",
  projectId: "celestial-sonar-363107",
  storageBucket: "celestial-sonar-363107.appspot.com",
  messagingSenderId: "1022148657798",
  appId: "1:1022148657798:web:3ddf68104d9c37d3ff4707",
  measurementId: "G-MJ9NVH5RSR",
};

const app = initializeApp(firebaseConfig);

export const functions = getFunctions(app);
// export const auth = getAuth(app);
export const db = getFirestore(app);

// if (process.env.NODE_ENV === "development") {
//   connectFirestoreEmulator(db, "127.0.0.1", 8080);

//   connectFunctionsEmulator(functions, "127.0.0.1", 5001);

//   connectAuthEmulator(auth, "http://localhost:9099");
// }
