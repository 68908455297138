export const createAddressString = (fullAddress) => {
  let address = "";
  for (const key in fullAddress) {
    if (fullAddress[key] && key !== "google_location") {
      address = address + `${fullAddress[key]}, `;
    }
  }

  return address.slice(0, -2);
};
