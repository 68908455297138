import Background1 from "../assets/images/bg-wallpaper-1.svg";
import Background2 from "../assets/images/bg-wallpaper-2.svg";
import Background3 from "../assets/images/bg-wallpaper-3.svg";
import Background4 from "../assets/images/bg-wallpaper-4.svg";

export const themeStyleBg = (style) => {
  switch (style) {
    case 1:
      return Background1;
    case 2:
      return Background2;
    case 3:
      return Background3;
    case 4:
      return Background4;
    default:
      return Background1;
  }
};
