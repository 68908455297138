import React from "react";
import {
  TikTok,
  Instagram,
  Link,
  Facebook,
  LinkedIn,
  Messenger,
  Snapchat,
  Twitter,
  WhatsApp,
  Youtube,
} from "../../constants/svgIcons";
import styles from "./style.module.css";

const SocialIcon = ({
  title = "",
  url = "",
  size = "22px",
  iconColor = "#fff",
  backgroundColor = "#000",
  border = "",
  containerStyles = {
    width: "48px",
    height: "48px",
  },
  onClick = () => {},
}) => {
  const getIcon = () => {
    switch (title) {
      case "Facebook":
        return Facebook;

      case "Messenger":
        return Messenger;

      case "Instagram":
        return Instagram;

      case "Twitter":
        return Twitter;

      case "YouTube":
        return Youtube;

      case "LinkedIn":
        return LinkedIn;

      case "TikTok":
        return TikTok;

      case "Snapchat":
        return Snapchat;

      case "WhatsApp":
        return WhatsApp;

      default:
        return Link;
    }
  };

  const Icon = getIcon();

  return (
    <a
      onClick={onClick}
      className={styles.background}
      style={{
        backgroundColor,
        border,
        ...containerStyles,
      }}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <Icon color={iconColor} size={size} />
    </a>
  );
};

export default SocialIcon;
