import "./imageViewer.css";
import styles from "./style.module.css";
import React from "react";
import ImageViewer from "react-simple-image-viewer";

const PreviewImage = ({
  images = [],
  currentImage = 0,
  closeImageViewer = () => {},
}) => {
  return (
    <ImageViewer
      src={images}
      currentIndex={currentImage}
      disableScroll={true}
      closeOnClickOutside={true}
      onClose={closeImageViewer}
      backgroundStyle={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
      rightArrowComponent={
        <button className={styles.imageViewerArrowRight}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            width="40"
            height="40"
            focusable="false"
          >
            <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
          </svg>
        </button>
      }
      leftArrowComponent={
        <button className={styles.imageViewerArrowLeft}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            width="40"
            height="40"
            focusable="false"
          >
            <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
          </svg>
        </button>
      }
    />
  );
};

export default PreviewImage;
